import { Grid, Typography } from "@mui/material"
import axios from "../api/axios"
import NonAuthLayout from "../Layout/NonAuthLayout"
import { MAILING_LIST_URL } from "./URLs"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import deleteText from "../text/deleteProfile.json"
import { useState, useEffect } from "react";
import DialogueBox from "../components/IndividualComponents/DialogueBox"

const DeleteProfileSuccessful = () => {
    const EMAIL_REGEX = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [displayMailingPopUp, setMailingDisplayPopUp] = useState(true);
    const [interestedEmail, setInterestedEmail] = useState("");
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    // the useEffect to trigger on first render
    useEffect(() => {
        setMailingDisplayPopUp(false);
        setTimeout(() => {
            setMailingDisplayPopUp(true);
        }, 1000)
    }, []);

    const closeMailingPopUp = async () => {
        if (!EMAIL_REGEX.test(interestedEmail)) { setInvalidEmail(true); return; }
        setLoading(true)

        try {
            await axios.post(MAILING_LIST_URL,
                JSON.stringify(
                { email: interestedEmail, list: "all" }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_MAILING_LIST_KEY}` },
                    withCredentials: true
                }
            ).then(() => {
                setTimeout(() => {
                    setSuccess(true)
                }, 1500)
            })
        } catch (err) {
            console.log(err?.response)
            setLoading(false)
        }

        setTimeout(() => {
            setLoading(false)
            setMailingDisplayPopUp(false);
        }, 3000)
    };

    const handleChange = (e) => {
        setInterestedEmail(e.target.value)
    }

    const onClose = (e) => {
        setMailingDisplayPopUp(false);
    }

    return (
        <NonAuthLayout>
            <Grid container style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <SEO
                    title={seoText.default.title}
                    description={seoText.default.description}
                />
                {process.env.REACT_APP_MAILINGLIST_ENABLED === "true" && displayMailingPopUp && (<DialogueBox
                    title={deleteText.mailingList.title}
                    body={deleteText.mailingList.text}
                    buttonVariant="impTitle"
                    buttonText="Stay Connected"
                    mailingList="true"
                    handleChange={handleChange}
                    onClick={closeMailingPopUp}
                    onClose={onClose}
                    email={interestedEmail}
                    invalidEmail={invalidEmail}
                    loading={loading}
                    success={success}
                />)}
                <Grid item style={{width: "max(50vw, 300px)"}}>
                    <Typography variant="h4" align="center">
                        {deleteText.successful}
                    </Typography>
                </Grid>
            </Grid>
        </NonAuthLayout>
    )
}

export default DeleteProfileSuccessful