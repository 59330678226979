import { Grid, Typography, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ActionCenter from "../components/CombinedComponents/ActionCenter";
import InitialActionCenter from "../components/CombinedComponents/InitialActionCenter";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";
import DialogueBox from "../components/IndividualComponents/DialogueBox";
import { completeProfilePage, matchingPage, settingsPage, subscriptionsPage } from "./URLs";
import FaceIcon from '@mui/icons-material/Face';
import SavingsIcon from '@mui/icons-material/Savings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import usePrefsDone from "../hooks/usePrefsDone";
import useSettingsCreated from "../hooks/useSettingsCreated";
import SettingsIcon from '@mui/icons-material/Settings';
import useGetUserMatchHistory from "../hooks/useGetUserMatchHistory";
import UserFeedCard from "../components/CombinedComponents/UserFeedCard";
import MailIcon from '@mui/icons-material/Mail';
import KMButton from "../components/IndividualComponents/KMButton";
import { useContext, useState, useEffect } from "react";
import { mainColors } from "../components/Theme/Colors/palette";
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import bannerText from "../text/subBanner.json"
import MobileInitialActionCenter from "../components/CombinedComponents/MobileInitialActionCenter";
import MobileActionCenter from "../components/CombinedComponents/MobileActionCenter";

const getStyles = (color) => {
    return {
        fontSize: "7vh",
        fill: color
    }
}


const Home = () => {

    let rightContentCard
    const accessTokenInfo = useAccessTokenInfo()
    const isReadyForMatches = accessTokenInfo?.readyForMatches || false
    const isSubbed = accessTokenInfo?.isSubbed || false
    const quickPrefsDone = usePrefsDone(accessTokenInfo?.email)
    const isEmailVerified = accessTokenInfo?.isEmailVerified || false
    const newMatches = accessTokenInfo?.newMatches || false
    const settingsCreated = useSettingsCreated(accessTokenInfo?.email)

    const [newMatchModal, setNewMatchModal] = useState(newMatches)
    const [displayPopUp, setDisplayPopUp] = useState(true);

    const matchHistory = useGetUserMatchHistory(accessTokenInfo?.email)

    const { isRemoveRightContent } = useContext(ResponsiveContext);
    const navigate = useNavigate()

    // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
    useEffect(() => {
        let returningUser = localStorage.getItem("seenPopUp");
        setDisplayPopUp(!returningUser);
    }, []);

    const closePopUp = () => {
        localStorage.setItem("seenPopUp", true);
        setDisplayPopUp(false);
    };

    const verifyYourEmail = {
        icon: <MailIcon style={getStyles("black")}/>,
        text: "Please verify your email. If the email didn't come through, contact support",
        func: () => null
    }
    const completeYourProfile = {
        icon: <FaceIcon style={getStyles("purple")}/>,
        text: "Continue to completing your profile or click on this card!",
        func: () => navigate(completeProfilePage)
    }
    const matchingComingSoon = {
        icon: <FavoriteIcon style={getStyles("red")}/>,
        text: "Matching is coming soon. Click here to find out more!",
        func: () => navigate(matchingPage)
    }
    const subscribe = {
        icon: <SavingsIcon style={getStyles("green")}/>,
        text: "Please subscribe so we can keep delivering the best matching services to you!",
        func: () => navigate(subscriptionsPage)
    }
    const goToMatching = {
        icon: <FavoriteIcon style={getStyles("red")}/>,
        text: "Head to matching to select the qualities you look for and match!",
        func: () => navigate(matchingPage)
    }
    const settings = {
        icon: <SettingsIcon style={getStyles("grey")}/>,
        text: "Check out your settings to make sure they are correct",
        func: () => navigate(settingsPage)
    }

    if (!isEmailVerified) {
        rightContentCard = !isRemoveRightContent ? (<MobileInitialActionCenter icon={verifyYourEmail.icon}
            text={verifyYourEmail.text} func={verifyYourEmail.func}/>) : (<InitialActionCenter icon={verifyYourEmail.icon}
        text={verifyYourEmail.text} func={verifyYourEmail.func}/>)
    } else if (!isReadyForMatches) {
        rightContentCard = !isRemoveRightContent ? (<MobileInitialActionCenter icon={completeYourProfile.icon}
            text={completeYourProfile.text} func={completeYourProfile.func}/>) : (<InitialActionCenter icon={completeYourProfile.icon}
            text={completeYourProfile.text} func={completeYourProfile.func}/>)
    } else if (process.env.REACT_APP_DISABLE_MATCHING === "true") {
        rightContentCard = !isRemoveRightContent ? (<MobileInitialActionCenter icon={matchingComingSoon.icon}
            text={matchingComingSoon.text} func={matchingComingSoon.func}/>) : (<InitialActionCenter icon={matchingComingSoon.icon}
            text={matchingComingSoon.text} func={matchingComingSoon.func}/>)
    } else if (process.env.REACT_APP_SUBSCRIPTION_ENABLED === "true" && !isSubbed) {
        rightContentCard = !isRemoveRightContent ? (<MobileInitialActionCenter icon={subscribe.icon}
            text={subscribe.text} func={subscribe.func}/>) : (<InitialActionCenter icon={subscribe.icon}
            text={subscribe.text} func={subscribe.func}/>)
    } else if (!quickPrefsDone || quickPrefsDone.data === "N") {
        rightContentCard = !isRemoveRightContent ? (<MobileInitialActionCenter icon={goToMatching.icon}
            text={goToMatching.text} func={goToMatching.func}/>) : (<InitialActionCenter icon={goToMatching.icon}
            text={goToMatching.text} func={goToMatching.func}/>)
    } else if (!settingsCreated || settingsCreated.data === 'User not found') {
        rightContentCard = !isRemoveRightContent ? (<MobileInitialActionCenter icon={settings.icon}
            text={settings.text} func={settings.func}/>) : (<InitialActionCenter icon={settings.icon}
            text={settings.text} func={settings.func}/>)
    } else {
        rightContentCard = !isRemoveRightContent ? (<MobileActionCenter/>) : (<ActionCenter/>)
    }

    return (
        <>
            <Modal open={newMatchModal}>
                <Grid container
                sx={{px: 4}}
                style={{
                    width: "100vw",
                    maxWidth: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: mainColors.background
                }}>
                    <Grid item
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Grid container
                        style={{
                            width: "min(550px, 100%)"
                        }}>
                            <Grid item xs={12} sx={{mb: 2}}>
                                <Typography variant="h3" align="center">
                                    You have new matches!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{mb: 2}}>
                                <Typography variant="h4" align="center">
                                    Check your Activity Feed for your new matches email and connect with them
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{mb: 3}}>
                                <Typography variant="h6" align="center">
                                    Remember to stay safe and follow our helpful tips on FAQs and Community Guidelines
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <KMButton variant="accentTitle"
                                text="Show me!" onClick={() => setNewMatchModal(false)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            {process.env.REACT_APP_SUBSCRIPTION_ENABLED !== "true" && displayPopUp && (
                <DialogueBox 
                    onClick={closePopUp}
                    onClose={closePopUp}
                    title={bannerText.title}
                    body={bannerText.banner}
                    buttonVariant="impTitle"
                    buttonText="Understood"
                />
            )}
            <MainBody>
                <SEO
                    title={seoText.home.title}
                    description={seoText.home.description}
                />
                { !isRemoveRightContent && rightContentCard }
                <Grid item xs={12}
                sx={{
                    px: "2vw",
                    py: "3vh"
                }}
                >
                    <Typography variant="h3">
                        Feed
                    </Typography>
                    { matchHistory?.data?.seen?.length > 0 && <Typography variant="h4" key={"seen"}>
                        Seen
                    </Typography> }
                    <Grid container width={"100%"}>
                    { matchHistory?.data?.seen && matchHistory.data.seen.map(profile => {
                        return (
                            <Grid item sx={{my:2}} xs={12} key={profile.user}>
                                <UserFeedCard name={profile.name} age={profile.age} height={profile.height} user={profile.user} likeOrDislike={profile.likeOrDislike}/>
                            </Grid>
                        )
                    }) }
                    </Grid>
                    { matchHistory?.data?.matches?.length > 0 && <Typography variant="h4" key={"matched"}>
                        Matched
                    </Typography> }
                    <Grid container width={"100%"}>
                    { matchHistory?.data?.matches && matchHistory.data.matches.map(profile => {
                        return (
                            <Grid item sx={{my:2}} xs={12} key={profile.user}>
                                <UserFeedCard name={profile.name} age={profile.age} height={profile.height} user={profile.user} email={profile.email}/>
                            </Grid>
                        )
                    }) }
                    </Grid>
                </Grid>
            </MainBody>

            <RightContent>
                {rightContentCard}
            </RightContent>
        </>
    )
}

export default Home
