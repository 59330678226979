import { useNavigate } from "react-router-dom"
import axios from "../api/axios"
import { cookiePolicy, homePage, loginPage, registerPage, MAILING_LIST_URL } from "./URLs"
import { Grid, Typography } from "@mui/material"
import WelcomeMessage from "../components/LandingComps/WelcomeMessage"
import desktopLanding from "../img/desktopLanding-min.jpg"
import desktopLandingServices from "../img/desktopLandingServices-min.jpg"
import OurServices from "../components/LandingComps/OurServices"
import ServiceTiles from "../components/LandingComps/ServiceTiles"
import { mainColors } from "../components/Theme/Colors/palette"
import Testimonials from "../components/LandingComps/Testimonials"
import Footer from "../components/CombinedComponents/Footer"
import { useRef } from "react"
import CookieConsent from "react-cookie-consent"
import { useState, useContext, useEffect } from "react";
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider"
import NonAuthNavBar from "../components/NavBar/NonAuthNavBar"
import KMButton from "../components/IndividualComponents/KMButton"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import imageAltText from "../text/imageAlt.json"
import homeText from "../text/home.json"
import DialogueBox from "../components/IndividualComponents/DialogueBox"

const Landing = () => {
    const { isTabletLanding, isLaptopLanding, resDesignLanding } = useContext(ResponsiveContext)
    const ref = useRef(null)
    const missionRef = useRef(null)
    const servicesRef = useRef(null)
    const testimonialsRef = useRef(null)
    const EMAIL_REGEX = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [displayMailingPopUp, setMailingDisplayPopUp] = useState(true);
    const [interestedEmail, setInterestedEmail] = useState("");
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate()

    const links = [
        { name: "Our Services", handleClick: () => servicesRef.current.scrollIntoView() },
        { name: "Testimonials", handleClick: () => testimonialsRef.current.scrollIntoView() }
    ]

    // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
    useEffect(() => {
        setMailingDisplayPopUp(false);
        setTimeout(() => {
            let returningUser = localStorage.getItem("seenMailingPopUp");
            setMailingDisplayPopUp(!returningUser);
        }, 5500)
    }, []);

    const closeMailingPopUp = async () => {
        if (!EMAIL_REGEX.test(interestedEmail)) { setInvalidEmail(true); return; }
        setLoading(true)

        try {
            await axios.post(MAILING_LIST_URL,
                JSON.stringify(
                { email: interestedEmail, list: "all" }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_MAILING_LIST_KEY}` },
                    withCredentials: true
                }
            ).then(() => {
                setTimeout(() => {
                    setSuccess(true)
                }, 1500)
            })
        } catch (err) {
            console.log(err?.response)
            setLoading(false)
        }

        setTimeout(() => {
            setLoading(false)
            localStorage.setItem("seenMailingPopUp", true);
            setMailingDisplayPopUp(false);
        }, 3000)
    };

    const handleChange = (e) => {
        setInterestedEmail(e.target.value)
    }

    const onClose = (e) => {
        setMailingDisplayPopUp(false);
    }

    return (
        <>
        <SEO
            title={seoText.landing.title}
            description={seoText.landing.description}
        />
        <CookieConsent>
            <div style={{ display: "flex" }}>
                <Typography variant="body1">
                    By continuing to use this website you agree to our
                </Typography>
                <Typography variant="body1" style={{ textDecoration: "underline", cursor: "pointer" }}
                sx={{ pl: "4px"}} onClick={() => navigate(cookiePolicy)}>
                    cookies policy
                </Typography>
            </div>
        </CookieConsent>

        <Grid container ref={ref}>
            <Grid item xs={12} height="auto"
            minHeight="100vh">
                <Grid container
                title={imageAltText.home.banner}
                style={{backgroundImage: `url(${desktopLanding})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
                minHeight: "100vh",
                minWidth: "100%",
                }}
                >
                    {process.env.REACT_APP_MAILINGLIST_ENABLED === "true" && displayMailingPopUp && (<DialogueBox
                        title={homeText.mailingList.title}
                        body={homeText.mailingList.text}
                        buttonVariant="impTitle"
                        buttonText="Join Now!"
                        mailingList="true"
                        handleChange={handleChange}
                        onClick={closeMailingPopUp}
                        onClose={onClose}
                        email={interestedEmail}
                        invalidEmail={invalidEmail}
                        loading={loading}
                        success={success}
                    />)}
                    <Grid item xs={12}>
                        <NonAuthNavBar links={links}/>
                    </Grid>
                    <Grid item xs={12}
                    style={{background: "rgba(0,0,0,0.5)",
                    height: `calc(100vh - ${resDesignLanding(64, 64, 60)})`,
                    display: "flex",
                    alignItems: "center"
                    }}
                    >
                        {
                            isLaptopLanding ?
                            <Grid container
                            direction="row-reverse"
                            width="100%"
                            height="100%"
                            display={ "flex"}
                            justifyContent={ "end"}
                            alignItems={"center"}
                            >
                                <Grid item
                                sx={{ mr: "50px" }}
                                >
                                    <WelcomeMessage/>
                                </Grid>
                            </Grid> :
                            isTabletLanding ?
                            <Grid container
                            direction="row-reverse"
                            width="100%"
                            height="100%"
                            display={ "flex"}
                            alignItems={"center"}
                            justifyContent={ "center"}
                            >
                                <Grid item>
                                    <WelcomeMessage/>
                                </Grid>
                            </Grid> :
                            <Grid container
                            direction="row-reverse"
                            width="100%"
                            display={ "flex"}
                            justifyContent={ "center"}
                            >
                                <Grid item>
                                    <WelcomeMessage/>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>

            {/* mission */}
            <Grid item xs={12} height="auto"
            minHeight="100vh"
            ref={missionRef}
            id="missionStatement"
            >
                <Grid container
                style={{
                background: mainColors.default,
                }}
                direction="row"
                >
                    {   
                        isLaptopLanding ?
                        <Grid item xs={4}
                        title={imageAltText.home.services}
                        style={{backgroundImage: `url(${desktopLandingServices})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "inherit",
                        minHeight:"100vh"
                        }}
                        display="flex"
                        justifyContent="flex-end"
                        >
                            <Grid container
                            style={{
                                width: "5vw",
                                height: "100%"}}
                            sx={{bgcolor: "rgba(255, 215, 111, 0.6)"}}
                            >
                            </Grid>
                        </Grid> :
                        <></>}

                    <Grid item xs={ isLaptopLanding ? 8 : 12}
                    display="flex"
                    alignItems="center"
                    sx={{px: resDesignLanding(100, 100, 60), py: resDesignLanding(150, 130, 100)}}
                    style={{ minHeight: "100vh" }}
                    >
                        <OurServices/>
                    </Grid>
                </Grid>
            </Grid>


            {/* Service cards */}
            <Grid item xs={12} height="auto"
            minHeight="100vh"
            ref={servicesRef}
            >
                <Grid container
                style={{
                background: mainColors.primary,
                }}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                >
                    <Grid item xs={12}>
                        <ServiceTiles/>
                    </Grid>
                </Grid>
            </Grid>

            {/* Testimonials */}
            <Grid item xs={12} height="auto"
            minHeight="100vh"
            ref={testimonialsRef}
            display="flex"
            >
                <Grid container
                style={{
                background: mainColors.default,
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    alignItems="center"
                    sx={{my:"15vh", px:"10vw"}}
                    >
                        <Testimonials/>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12} height="auto">
                <Grid container
                style={{
                background: mainColors.primary,
                }}
                direction="row"
                >
                    <Grid item xs={12}
                    sx={{my:"5vh", px:"10vw"}}
                    
                    >
                        <Footer/>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

        {/* <ScrollTop/> */}

        </>
    )
}

export default Landing
