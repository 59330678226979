import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Matchmaker from './pages/Matchmaker';
import Admin from './pages/Admin';
import Missing from './pages/Missing';
import Unauthorized from './pages/Unauthorized';
import Landing from './pages/Landing';
import RequireAuth from './auth/RequireAuth';
import LogRocket from 'logrocket';
import PersistLogin from './auth/PersistLogin';
import CompleteProfile from './pages/CompleteProfile'
import { Routes, Route } from 'react-router-dom';
import { default as ROLES } from './auth/Roles';
import { adminPage, completeProfilePage, contactUsPage, faqPage, cookiePolicy, dataProtectionPolicy, privacyPolicy, forgotPasswordPage, galleryPage, guidelinesPage, heroPage, homePage, landingPage, loginPage, matchingPage, matchmakerPage, matchProfilePage, paymentFailedPage, paymentPage, paymentSuccessPage, registerPage, resetPasswordPage, settingsPage, subscriptionsPage, termsAndConditionsPage, unauthorizedPage, updateProfilePage, usefulLinksPage, verifyEmailPage, verifyUser, DeleteProfileSuccessfulPage, revisitProfilePage, RemoveMailingListSuccessfulPage } from './pages/URLs';
import Matching from './pages/Matching';
import MatchProfile from './pages/MatchProfile';
import Payment from './pages/Payment';
import PaymentFailed from './pages/PaymentFail';
import PaymentSuccess from './pages/PaymentSuccess';
import UpdateProfile from './pages/UpdateProfile';
import Settings from './pages/Settings';
import Subscriptions from './pages/Subscriptions';
import Hero from './pages/Hero';
import Faq from './pages/FAQ';
import AuthLayout from './pages/AuthLayout';
import TermsAndConditions from './pages/TermsAndConditions';
import Guidelines from './pages/Guidelines';
import ContactUs from './pages/ContactUs';
import UsefulLinks from './pages/UsefulLinks';
import VerifyEmail from './pages/VerifyEmail';
import VerifyUser from './pages/VerifyUser';
import Gallery from './pages/Gallery';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import RemoveFromMailingListSuccess from './pages/RemoveMailingListSuccess';
import CookiePolicy from './pages/CookiePolicy';
import DataProtection from './pages/DataProtection';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DeleteProfileSuccessful from './pages/DeleteProfileSuccessful';
import MatchingDisabled from './components/CombinedComponents/MatchingDisabled';
import RevisitProfile from './pages/RevisitProfile';

function App() {

  LogRocket.init('h9wnbe/km-app');
  return (
    <>
    { !(process.env.REACT_APP_HERO_TOGGLE === "true") ?
      (<Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        {/* <Route path={"/"} element={<Hero />} /> */}
        <Route path={landingPage} element={<Landing />} />
        <Route path={loginPage} element={<Login />} />
        <Route path={registerPage} element={<Register />} />
        <Route path={unauthorizedPage} element={<Unauthorized />} />
        <Route path={forgotPasswordPage} element={<ForgotPassword />} />
        <Route path={resetPasswordPage} element={<ResetPassword />} />
        <Route path={faqPage} element={<Faq />} />
        <Route path={cookiePolicy} element={<CookiePolicy />} />
        <Route path={dataProtectionPolicy} element={<DataProtection />} />
        <Route path={privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={termsAndConditionsPage} element={<TermsAndConditions />} />
        <Route path={guidelinesPage} element={<Guidelines />} />
        <Route path={contactUsPage} element={<ContactUs />} />
        <Route path={usefulLinksPage} element={<UsefulLinks />} />
        <Route path={verifyEmailPage} element={<VerifyEmail />} />
        <Route path={verifyUser} element={<VerifyUser />} />
        <Route path={DeleteProfileSuccessfulPage} element={<DeleteProfileSuccessful />} />
        <Route path={RemoveMailingListSuccessfulPage} element={<RemoveFromMailingListSuccess />} />

        {/* we want to protect these routes */}
        <Route element={<AuthLayout/>}>
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path={homePage} element={<Home />} />

              { process.env.REACT_APP_DISABLE_MATCHING === "true" ? (
                <>
                  <Route path={matchingPage} element={<MatchingDisabled />} />
                  <Route path={matchProfilePage} element={<MatchingDisabled />} />
                </>
              ) : (
                <>
                  <Route path={matchingPage} element={<Matching />} />
                  <Route path={matchProfilePage} element={<MatchProfile />} />
                  <Route path={revisitProfilePage} element={<RevisitProfile />} />
                </>
              )}

              <Route path={completeProfilePage} element={<CompleteProfile />} />
              <Route path={paymentPage} element={<Payment />} />
              <Route path={paymentFailedPage} element={<PaymentFailed />} />
              <Route path={paymentSuccessPage + "/:qParams"} element={<PaymentSuccess />} />
              <Route path={updateProfilePage} element={<UpdateProfile />} />
              <Route path={settingsPage} element={<Settings />} />
              <Route path={subscriptionsPage} element={<Subscriptions />} />
              <Route path={galleryPage} element={<Gallery />} />
            </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Matchmaker]} />}>
            <Route path={matchmakerPage} element={<Matchmaker />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path={adminPage} element={<Admin />} />
          </Route>

        </Route>

        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>)
     : (
      <Routes>
        <Route path="*" element={<Hero/>} />
      </Routes>
    )
  }
  </>
  );
}

export default App;