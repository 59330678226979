import { Grid, Typography } from "@mui/material"
import axios from "../api/axios"
import { useParams } from 'react-router-dom';
import NonAuthLayout from "../Layout/NonAuthLayout"
import { MAILING_LIST_URL } from "./URLs"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import removeMailingListText from "../text/removeMailingList.json"
import { useEffect } from "react";

const RemoveFromMailingListSuccess = () => {
    const { emailToken } = useParams()
    // the useEffect to trigger on first render
    // this returns a warning: 'Warning: useEffect must not return anything besides a function, which is used for clean-up.'
    useEffect(async () => {
        try {
            await axios.post(MAILING_LIST_URL,
                JSON.stringify({ email: emailToken, list: "unsubscribeToken" }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_MAILING_LIST_KEY}` },
                    withCredentials: true
                }
            )
        } catch (err) {
            console.log(err?.response)
        }
    }, []);

    return (
        <NonAuthLayout>
            <Grid container style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <SEO
                    title={seoText.default.title}
                    description={seoText.default.description}
                />
                <Grid item style={{width: "max(50vw, 300px)"}}>
                    <Typography variant="h4" align="center">
                        {removeMailingListText.successful}
                    </Typography>
                </Grid>
            </Grid>
        </NonAuthLayout>
    )
}

export default RemoveFromMailingListSuccess