import { Fragment, useState, useContext } from 'react';
import KMButton from '../IndividualComponents/KMButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingComp from '../IndividualComponents/LoadingComp';
import DialogTitle from '@mui/material/DialogTitle';
import ResponsiveContext from '../../ResponsiveDesign/ResponsiveProvider';
import { mainColors } from "../Theme/Colors/palette";
import { Grid, Typography, TextField } from '@mui/material';

const DialogueBox = (props) => {
  const { isRemoveRightContent, isTablet, isLaptop } = useContext(ResponsiveContext);
  const fullScreen = !isLaptop;

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={props.onClose}
        aria-labelledby="dialogue-box"
        style={{
          textAlign: "center",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        PaperProps={{
          style: {
            backgroundColor: mainColors.background,
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: mainColors.primary,
            padding: "15px"
          }
        }}
      >
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: mainColors.grey,
          }}
        >
          <CloseIcon/>
        </IconButton>
        <DialogTitle id="dialogue-box">
          <Typography variant='h3'>{props.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='h6' style={{ color: "black" }}>{props.body}</Typography>
          </DialogContentText>
          { props.mailingList === "true" ? (
          <Grid item xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={2}
          >
            <TextField
              size="small"
              fullWidth
              sx={{ mb: "1vh",
                  ".MuiOutlinedInput-notchedOutline": { borderColor: mainColors.primary },
                  "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": { borderColor: mainColors.primary },
                      "&.Mui-focused fieldset": { borderColor: mainColors.primary },
                  },
                  input: { color: "black", textAlign: "center" }
              }}
              onChange={props.handleChange}
              value={props.email}
            />
          </Grid>) : (<></>)
          }
          { props.mailingList === "true" && props.invalidEmail ?
            (<Grid item xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant='h6'
                style={{ color: mainColors.important, textAlign: "center" }}
                sx={{ mb: "20px" }}
                align="center"
              >
              Please enter a valid email
              </Typography>
            </Grid>) : (<></>)
          }
          { props.mailingList === "true" && props.success ?
            ( <Grid item xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant='h6'
                  style={{ color: mainColors.green, textAlign: "center" }}
                  sx={{ mb: "20px" }}
                  align="center"
                >
                Thank you for joining our mailing list!
                </Typography>
              </Grid>
            ) : (<></>)
          }
        </DialogContent>
        <DialogActions>
          { props.mailingList === "true" && props.loading ?
              <LoadingComp
                width="max(4.2vw, 55px)"
                mainColor={mainColors.background}
                secColor={mainColors.primary}
              /> :
              <KMButton endArrow
                variant={props.buttonVariant}
                text={props.buttonText}
                onClick={props.onClick}
              />
          }

        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DialogueBox;